<template>
    <div>
        <div class="landing" v-if="!$auth.loading">
            <div class="banner-block">
                <v-row class="ma-0">
                    <div class="banner-block-text">
                        <h1 class="text-lg-h2 font-weight-regular">
                            <span class="main--text">Data Heroes </span> - аналитический сервис-помощник для
                            салонов красоты
                        </h1>
                        <p class="py-14 font-weight-regular">
                            Мы знаем, как сделать так, чтобы вы зарабатывали больше. Наш сервис позволит
                            увеличить частоту и количество посещений клиентов, в несколько раз сократить
                            расходы на привлечение новых посетителей, а также расскажет о других возможностях
                            повышения эффективности вашего бизнеса.
                        </p>
                    </div>

                    <img height="490" width="624" src="@/assets/img/landing/01.svg" />
                </v-row>

                <v-row class="ma-0">
                    <div>
                        <v-btn
                            color="main"
                            rounded
                            x-large
                            class="white--text"
                            @click="openAuthDialog('register')"
                            >Создать аккаунт</v-btn
                        >
                        <span class="mx-6">ИЛИ</span>
                        <v-btn
                            color="main"
                            rounded
                            x-large
                            class="white--text"
                            @click="openAuthDialog('login')"
                            outlined
                            >Войти в аккаунт</v-btn
                        >
                        <p class="mt-2 ml-1 text-body-2">Бесплатный пробный период 14 дней</p>
                    </div>
                </v-row>
            </div>
            <v-row class="ma-0">
                <div class="tariffs my-12 banner-block" v-if="cfg.tariffEnabled">
                    <v-row justify="center" class="ma-0">
                        <h1 class="display-2 font-weight-regular">Тарифы</h1>
                    </v-row>

                    <v-row justify="space-between">
                        <Tariff1 margin="my-10 mr-13 ml-2"></Tariff1>
                        <Tariff2 margin="my-10 mx-13"></Tariff2>
                        <Tariff3 margin="my-10 mx-13"></Tariff3>
                    </v-row>
                </div>
            </v-row>
            <div class="banner-block mt-16">
                <v-row class="ma-0">
                    <img width="496" height="337" src="@/assets/img/landing/02.svg" />
                    <div class="banner-block-text">
                        <h1 class="text-lg-h2 font-weight-regular">
                            <span class="main--text">Data Heroes</span> - дружит с другими системами,
                            например, ПрофСалон, YClients или Universe Soft, DIKIDI
                        </h1>
                        <p class="py-14 font-weight-regular">
                            По мнению наших клиентов, мы - необходимое дополнение подобных систем. По нажатию
                            одной кнопки наш сервис синхронизируется с ПрофСалон, YClients, Universe Soft или
                            другой системой, и вы получаете подробную аналитику по своим посетителям.
                        </p>
                        <div>
                            <div>
                                <v-btn
                                    color="main"
                                    rounded
                                    x-large
                                    class="white--text"
                                    @click="openAuthDialog('register')"
                                    >Зарегистрироваться бесплатно</v-btn
                                >
                                <p class="mt-2 ml-12 text-body-2">Бесплатный пробный период 14 дней</p>
                            </div>
                        </div>
                    </div>
                </v-row>
            </div>
            <div class="banner-block mt-16 mb-16">
                <v-row class="ma-0">
                    <div class="banner-block-text">
                        <h1 class="text-lg-h2 font-weight-regular">
                            <span class="main--text"> Data Heroes</span> - отзывчивая система, которая
                            подстроится под вас
                        </h1>
                        <p class="py-14 font-weight-regular">
                            Хотите больше? Мы сделаем это для вас! Полностью погрузимся в потребности и
                            реализуем любой дополнительный отчет именно для вашего бизнеса.
                        </p>
                        <div>
                            <div>
                                <v-btn
                                    color="main"
                                    rounded
                                    x-large
                                    class="white--text"
                                    @click="openAuthDialog('register')"
                                    >Зарегистрироваться бесплатно</v-btn
                                >
                                <p class="mt-2 ml-12 text-body-2">Бесплатный пробный период 14 дней</p>
                            </div>
                        </div>
                    </div>

                    <img width="492" height="333" src="@/assets/img/landing/03.svg" />
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import cfg from '../../config'

import AuthDialog from '@root/src/components/landing/AuthDialog.vue'
import TextField from '../components/inputs/TextField.vue'

export default {
    name: 'Landing',
    components: {
        TextField,
        AuthDialog,
    },
    data: () => ({ cfg }),

    methods: {
        openAuthDialog(startSection) {
            this.$store.commit('setAuthDialogStartSection', startSection)
            this.$store.commit('setIsAuthDialog', true)
        },
    },
    created() {},
}
</script>
<style scoped lang="sass">
.landing
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding-top: 5%

.text-padding-top
    padding-top: 60px

.banner-block
    width: 1320px
    margin-left: 170px

.banner-block-text
    width: 600px

.test
    background-color: red
    width: 50px
    height: 50px

.d
    width: 1400px
</style>
